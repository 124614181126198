import React, { Component } from 'react'
import LoginForm from '../components/LoginForm'
import api from '../services/api'
import Auth from '../services/Auth';

export class Root extends Component {


    componentDidMount = () => {
            document.title = 'Power Wing | Login'
        const auth = localStorage.getItem("auth");
        const token = localStorage.getItem("token");
        
        if (auth && token) {
            Auth.login(token, () => this.props.history.push('/dashboard'))
        }
    }
    
    getUserData = async (e, stateHolder) => {
        e.preventDefault()
        const username = e.target.elements.username.value
        const password = e.target.elements.password.value
        const formData = new FormData();
        formData.append("user", username);
        formData.append("password", password);
        const res = await api.post('/admin/login.php/', formData)
        const { status , jwt } = res.data;
        // console.log(res.data);
        if (status === 'Authorized User') {
            stateHolder.setState({
                data: '1',
                token: jwt,
            })
            Auth.login(jwt,() => this.props.history.push('/dashboard'))
        } else if (status === 'UnAuthorized User') {
            stateHolder.setState({
                data: '0',
                token: ""
            })
            // console.log("Not Ok")

        }
    }

    render() {

        return (
            <div className="container mx-auto p-5 sm:p-6 md:p-7 lg:p-8 xl:p-10 h-full" id='root'>
                <div className="flex flex-wrap items-center justify-center w-full h-full">
                    <div className="w-1/2 text-center border-r">
                        <h1 className="text-4xl font-bold">Power Wing Group</h1>
                        <p className="text-xl font-light tracking-wider">We walk with you</p>
                    </div>
                    <div className="w-1/2">
                        <LoginForm getUserData={this.getUserData} />
                    </div>
                </div>
            </div>
        )
    }
}

