import React, { Component } from 'react'
import $ from 'jquery'
import {ChevronRight,ChevronLeft} from 'react-feather'



class ProductsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            datas: []
        }
    }

    componentDidMount = () => {
        // feather.replace()
    }
    
    shouldComponentUpdate(nextProps,nextState){
        if(nextProps !== this.props){
        // feather.replace()
            return true
        }
        return false
    }


    componentWillReceiveProps = (nextProps) => {
        if (nextProps.deleteStatus === 'success') {
            $('.delete-alert-success').css('display', 'flex');
        } else if (nextProps.deleteStatus === 'failed') {
            $('.delete-alert-failed').css('display', 'flex');

        } else {
            $('.delete-alert-success').fadeOut();
            $('.delete-alert-failed').fadeOut();
        }
    }

    onDelete = (id) => {
        this.props.deleteProduct(id)
    }

    onEdit = (id) => {
        this.props.editProduct(id)
    }

    onNext = () => {
        this.props.clickNext();
    }

    onPrevious = () => {
        this.props.clickPrevious();
    }

    render() {
        const { datas,showNext, showPrev } = this.props
        return (
            <div className="p-5 w-1/2 overflow-y-auto h-full">
                <div className="hidden delete-status"></div>
                <div className="alert delete-alert-success rounded-lg flex justify-between items-center bg-green-200 text-green-600 text-sm px-4 py-3 mb-4" role="alert">
                    <p>Success! Your post is successfully deleted.</p>
                    <i data-feather="x" className="feather-sm cursor-pointer close-alert"></i>
                </div>
                <div className="alert delete-alert-failed rounded-lg flex justify-between items-center bg-red-200 text-red-600 text-sm px-4 py-3 mb-4" role="alert">
                    <p>Failed! Your post is not deleted.</p>
                    <i data-feather="x" className="feather-sm cursor-pointer close-alert"></i>
                </div>
                {
                    datas.map((data, index) =>
                        <div className="flex items-center w-full border-b p-3" key={index}>
                            <div className="w-5/6 pr-3 border-r overflow-hidden">
                                <span className="text-sm text-gray-600">{data.posted_date}</span>
                                <h3 className="text-lg font-semibold tracking-wide my-1">{data.name}</h3>
                                <p className="text-sm tracking-wide text-teal-500">
                                    <span className="text-gray-900 font-semibold">Company: </span>{data.company_name}
                                    <span className="text-gray-900 font-semibold ml-5">Category: </span>{data.category_name}
                                    <br/>
                                    <span className="text-gray-900 font-semibold">Model No.: </span>{data.model}
                                </p>
                            </div>
                            <div className="w-1/6 pl-3 text-center">
                                <div onClick={() => this.onEdit(data.id)} className="text-sm text-orange-500 font-semibold tracking-wide cursor-pointer mb-1">Edit</div>
                                <div onClick={() => this.onDelete(data.id)} className="text-sm text-red-500 font-semibold tracking-wide cursor-pointer mt-1">Delete</div>
                            </div>
                        </div>
                    )
                }

                <div className="flex item-center justify-between w-full p-3 mb-24">
                    {
                        showPrev &&
                    <div onClick={this.onPrevious} className="inline-block bg-gray-200 text-gray-600 hover:bg-gray-300 hover:text-gray-700 rounded-full flex items-center justify-center w-10 h-10 mr-2 cursor-pointer"><ChevronLeft/></div>
                    
                    }
                    {
                        showNext &&
                    <div onClick={this.onNext} className="inline-block bg-gray-200 text-gray-600 hover:bg-gray-300 hover:text-gray-700 rounded-full flex items-center justify-center w-10 h-10 mr-2 cursor-pointer"><ChevronRight/></div>
                    }
                </div>

            </div>
        )
    }
}

export default ProductsList
