import React, { Component } from 'react'
import $ from 'jquery'
import api from '../services/api'
import auth from '../services/Auth'

class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPwd: '',
            newPwd: '',
            confirmPwd: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.changePswdStatus) {
            $('.change-password-modal').css('display', 'flex');
        }
    }

    closePswdModal = () => {
        $('.change-password-modal').css('display', 'none');
        $('.changepwd-alert-success').fadeOut();
    }

    onCurrentPwdChange = (e) => {
        this.setState({
            currentPwd: e.target.value
        })
    }

    onNewPwdChange = (e) => {
        const { confirmPwd } = this.state
        if (confirmPwd === e.target.value) {
            $('#pwdChange').removeAttr('disabled');
            $('#pwdChange').removeClass('opacity-25');
        } else {
            $('#pwdChange').attr('disabled', 'disabled');
            $('#pwdChange').addClass('opacity-25');
        }

        this.setState({
            newPwd: e.target.value
        })
    }

    onConfirmPwdChange = (e) => {
        const { newPwd } = this.state
        if (newPwd === e.target.value) {
            $('#pwdChange').removeAttr('disabled');
            $('#pwdChange').removeClass('opacity-25');
        } else {
            $('#pwdChange').attr('disabled', 'disabled');
            $('#pwdChange').addClass('opacity-25');
        }
        this.setState({
            confirmPwd: e.target.value
        })
    }

    onSubmit = async (e) => {
        e.preventDefault()
        this.setState({
            currentPwd : '',
            newPwd : '',
            confirmPwd : ''
        })
        const { currentPwd, newPwd } = this.state
        const formData  = new FormData();
        formData.append('currentPwd' , currentPwd);
        formData.append('newPwd', newPwd);
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        };
        const result = await api.post('/admin/changePassword.php', formData , config);
        // console.log(if(result.data == "Retrieve data from token error"));
        if (result.data === 1) {
            $('.changepwd-alert-success').css('display', 'flex');
            window.alert('Password Successfully Change.Please Login Back.');
            auth.logout(() => this.props.history.push("/"));
        }
        else {
            window.alert('Sorry! You are unauthorized user.');
            auth.logout(() => this.props.history.push("/"));
        }
        // else{
        //     $('.changepwd-alert-failed').css('display', 'flex');
        //     setTimeout(() => {
        //         auth.logout(() =>this.props.history.push("/"))
        //     },1000);
        // }
    }

    render() {
        const {currentPwd,newPwd,confirmPwd} = this.state
        return (
            <div className="change-password-modal z-40 absolute inset-0 w-full h-full p-5 flex items-center justify-center">
                <div className="p-5 bg-white rounded-lg">
                    <div className="flex flex-no-wrap items-center justify-between border-b pb-4">
                        <p className="text-lg text-gray-700 font-semibold">Change Your Password</p>
                        <div onClick={this.closePswdModal}>
                            <i data-feather="x" className="block close-change-password cursor-pointer"></i>
                        </div>
                    </div>
                    <div className="alert changepwd-alert-success rounded-lg flex justify-between items-center bg-green-200 text-green-600 text-sm px-4 py-3 mb-4" role="alert">
                            <p>Your password is successfully changed.</p>
                        </div>
                        <div className="alert changepwd-alert-failed rounded-lg flex justify-between items-center bg-red-200 text-red-600 text-sm px-4 py-3 mb-4" role="alert">
                            <p>Your are unauthorized user.</p>
                        </div>
                    <form onSubmit={this.onSubmit} className="mt-4" method="post">
                        <label htmlFor="currentPwd" className="text-gray-600 font-semibold text-sm">Current Password</label>
                        <input onChange={(e) => this.onCurrentPwdChange(e)} type="password" value={currentPwd || ''} id="currentPwd" className="bg-gray-200 border-2 border-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Enter your current password" required />
                        <label htmlFor="newPwd" className="text-gray-600 font-semibold text-sm">New Password</label>
                        <input onChange={(e) => this.onNewPwdChange(e)} type="password" value={newPwd || ''} id="newPwd" className="bg-gray-200 border-2 border-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Enter new password" required />
                        <label htmlFor="confirmPwd" className="text-gray-600 font-semibold text-sm">Confirm New Password</label>
                        <input onChange={(e) => this.onConfirmPwdChange(e)} type="password" value={confirmPwd || ''} id="confirmPwd" className="bg-gray-200 border-2 border-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Re-Enter new password" required />
                        <div className="text-right pt-4 border-t mt-4">
                            <input type="submit" value="Change Password" id="pwdChange" className="bg-teal-500 text-white py-2 px-3 rounded-lg cursor-pointer hover:bg-teal-400 opacity-25" disabled />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default ChangePassword
