class Auth {
    constructor() {
        this.authenticated = false;
        this.token = "";
    }

    login = (token,cb) => {
        this.authenticated = true;
        this.token = token;
        localStorage.setItem("auth",this.authenticated)
        localStorage.setItem("token",this.token)

        cb()
    }

    logout = (cb) => {
        this.authenticated = false;
        this.token = "";
        localStorage.removeItem("auth");
        localStorage.removeItem("token");
        cb()
    }

    isAuthenticated = () => {
        return this.authenticated
    }

}

export default new Auth()