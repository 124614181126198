import React from 'react';
import { Root } from './Root';
import {  Route, Switch, withRouter } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute'
import '../App.css';
import Dashboard  from './Dashboard'
import ErrorPage from '../components/ErrorPage'
function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Root} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <Route component={ErrorPage}/>
      </Switch>
    </div>
  );
}

export default withRouter(App);
