import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import AddNewProduct from '../components/NewProduct'
import ProductList from '../components/ProductsList'
import NavBar from '../components/NavBar'
import api from '../services/api'
import auth from '../services/Auth'


class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            companies: [],
            categories: [],
            datas: [],
            status: '0',
            imgFailed: false,
            deleteStatus: '0',
            updateStatus: '0',
            editData: {},
            modelStatus: false,
            editClick: false,
            offset: 0,
            limit: 15,
            totalCount: 6,
            clickStatus: true,
            loading: false,
            showPrev: true,
            showNext: false,
        }
    }

    componentDidMount = () => {
        this.getData()
        document.title = 'Power Wing | Dashboard'

    }


    getData = async () => {
        this.setState({
            loading: true
        })
        const comps = await api.get('/admin/getCompanies.php')
        const cats = await api.get('/admin/getCategories.php')
        const products = await api.get(`/admin/getProducts.php/?limit=${this.state.limit}&&offset=0`)
        let { limit, showNext } = this.state

        let sizeOfProducts = products.data.result.length
        if (sizeOfProducts > 0) {
            if (sizeOfProducts === limit) {
                showNext = true
            }
            else {
                showNext = false
            }
            this.setState({
                companies: comps.data,
                categories: cats.data,
                datas: products.data.result,
                showPrev: false,
                showNext,
            })
        } else {
            this.setState({
                companies: comps.data,
                categories: cats.data,
            })
        }
    }

    onAddNewProduct = async (data) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        };
        const result = await api.post('/admin/saveProduct.php', data, config)
        console.log(result);
        if (result.data === 1) {
            this.getData()
            this.setState({
                status: 'success',
                modelStatus: false
            })
        } else if (result.data === 2) {
            window.alert('Sorry! You are unauthorized user.');
            auth.logout(() => this.props.history.push("/"));
        }else if(result.data === 3){
            this.setState({
                imgFailed: true
            })
        }else {
            this.setState({
                status: 'failed'
            });
            // setTimeout(() => {
            //     auth.logout(() =>this.props.history.push("/admin"))
            // }, 3000)

        }

        // for dimissing after 3sec
        setTimeout(() => {
            this.setState({ status: '0', imgFailed: false });
        }, 3000)
    }

    deleteProduct = async (id) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        };
        // const result = await api.post('/admin/deleteProduct.php', data, config)


        const result = await api.get(`/admin/deleteProduct.php?id=${id}`, config)

        if (result.data === 1) {
            this.getData()
            this.setState({
                deleteStatus: 'success'
            })
        } else if (result.data === 2) {
            window.alert('Sorry! You are unauthorized user.');
            auth.logout(() => this.props.history.push("/"));
        } else {
            this.setState({
                deleteStatus: 'failed'
            })
        }

        // for dimissing after 2sec
        setTimeout(() => {
            this.setState({ deleteStatus: '0' });
        }, 2000)
    }

    editProduct = async (id) => {
        this.setState({
            editClick: true
        });
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        };
        const result = await api.get(`/admin/getProduct.php?id=${id}`, config);
        if (result.data === 2) {
            window.alert('Sorry! You are unauthorized user.');
            auth.logout(() => this.props.history.push("/")); 
        }
        else if (result.data) {
            this.setState({
                editData: result.data
            })
        }
    }

    onUpdateProduct = async (data) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        };
        // console.log("Data in update" , data)
        const result = await api.post('/admin/editProduct.php', data, config)

        if (result.data === 1) {
            this.getData()
            this.setState({
                updateStatus: 'success',
                modelStatus: false,
                editClick: false,
                editData: {}
            })
        } else if (result.data === 2) {
            window.alert('Sorry! You are unauthorized user.!');
            auth.logout(() => this.props.history.push("/")); 
        } else if(result.data === 3){
            this.setState({
                imgFailed: true
            })
        }else {
            this.setState({
                updateStatus: 'failed',
                editClick: false,
                editData: {}
            })
        }

        // for dimissing after 3sec
        setTimeout(() => {
            this.setState({ updateStatus: '0' , imgFailed: false});
        }, 3000)
    }

    cancelUpdate = () => {
        this.setState({
            editClick: false,
        })
    }

    clickNext = async () => {
        let { offset, limit, showNext, totalCount } = this.state
        let val = offset + limit
        if (val < totalCount) {
            offset = val
        }
        if ((offset * 2 + limit) > totalCount) {
            showNext = false
        }

        const products = await api.get(`/admin/getProducts.php/?limit=${limit}&&offset=${offset}`)
        this.setState({
            offset,
            showNext,
            showPrev: true,
            datas: products.data.result,
        })
    }

    clickPrevious = async () => {
        let { offset, limit, showPrev } = this.state

        offset -= limit
        //don't need if button dimiss
        // if(offset < 0){
        //     offset = 0
        // }
        if (offset === 0) {
            showPrev = false
        }
        const products = await api.get(`/admin/getProducts.php/?limit=${limit}&&offset=${offset}`)
        this.setState({
            offset,
            showNext: true,
            datas: products.data.result,
            showPrev,
        })
    }

    render() {
        const { companies, categories, datas, status,updateStatus, imgFailed, modelStatus, deleteStatus, editStatus, editData, editClick, showNext, showPrev } = this.state
        return (
            <div className="h-full text-gray-900">
                <div className="relative w-full h-full inset-0 overflow-hidden">
                    <NavBar {...this.props} />
                    <div className="flex w-full h-full">
                        {
                            editClick ?
                                companies.length > 0 && categories.length > 0 && Object.entries(editData).length > 0 &&
                                <AddNewProduct companies={companies} categories={categories} onUpdateProduct={this.onUpdateProduct} status={status} updateStatus= {updateStatus} modelStatus={modelStatus} editData={editData} cancelUpdate={this.cancelUpdate} imgFailed={imgFailed} />
                                :
                                companies.length > 0 && categories.length > 0 &&
                                <AddNewProduct companies={companies} categories={categories} onAddNewProduct={this.onAddNewProduct} status={status} updateStatus= {updateStatus} modelStatus={modelStatus} imgFailed={imgFailed} />
                        }

                        {
                            datas.length > 0 &&
                            <ProductList datas={datas} deleteProduct={this.deleteProduct} deleteStatus={deleteStatus} editProduct={this.editProduct} editStatus={editStatus} clickNext={this.clickNext} clickPrevious={this.clickPrevious} showNext={showNext} showPrev={showPrev} />
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(Dashboard);