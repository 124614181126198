import React, { Component } from 'react'
import auth from '../services/Auth'
import ChangePassword from './ChangePassword';
import {Key,LogOut} from 'react-feather'
 class NavBar extends Component {

    constructor(props){
        super(props)
        this.state = {
            changePswdStatus : false
        }
    }

    logout = () => {
        // console.log("Logout")
        auth.logout(() =>this.props.history.push("/"))
    }

    changePassword = () => {
        this.setState({
            changePswdStatus : true
        })
    }

    render() {
        return (
            <div>
            <nav className="flex flex-no-wrap justify-between items-center w-full py-3 px-5 border-b bg-white relative">
            <h1 className="text-xl font-bold">Dashboard</h1>
            <div>
                <div onClick={this.changePassword} className="inline-block ml-4 relative open-tooltip open-change-password">
                    <Key className="text-teal-500 cursor-pointer hover:text-teal-300"/>
                    <div className="tooltip py-2 px-3 rounded-lg absolute text-sm whitespace-no-wrap text-white mt-4">Change Password</div>
                </div>
                <div onClick={this.logout} className="inline-block ml-4 relative open-tooltip">
                    <LogOut className="text-teal-500 cursor-pointer hover:text-teal-300"/>
                    <div onClick={this.logout} className="tooltip py-2 px-3 rounded-lg absolute text-sm whitespace-no-wrap text-white mt-4">Logout</div>
                </div>
            </div>
        </nav>
            <ChangePassword {...this.props} changePswdStatus={this.state.changePswdStatus}/>
        </div>
        )
    }
}

export default NavBar
