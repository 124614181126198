import React, { Component } from 'react'
import $ from 'jquery'
import { Config } from '../services/Config'

const isImage = require('is-image');

class NewProduct extends Component {
    constructor(props) {
        super(props)
        this.modelInput = React.createRef()
        this.state = {
            modelStatus: this.props.modelStatus,
            notImage: false,
            companies: [],
            categories: [],
            items: [],
            file: '',
            userInput: {
                modelNumber: '',
                productDetails: ''
            },
            selectedFields: {
                productName: '',
                companyName: 1,
                productCategory: 1
            }

        }
        this.lastId = -1
    }

    componentDidMount = () => {
        const { editData } = this.props
        if (editData && Object.entries(editData).length > 0) {
            $('.image-placeholder').hide();
            $('.image-edit-btn').css('display', 'inline-block');
            this.setState({
                id : editData.id,
                previewImage: `${Config.apiUrl}/admin/uploads/${editData.image}`,
                items: editData.userInput,
                selectedFields: {
                    productName: editData.productName,
                    companyName: editData.companyName,
                    productCategory: editData.productCategory
                }
            })
        }
        $('.select-img').on('click', function () {
            $('#selectImage').trigger('click');
            $('.image-placeholder').fadeOut(function () {
                $('#previewImage img').remove();
                $('.image-edit-btn').css('display', 'inline-block');
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            if (nextState.notImage) {
                $('.image-upload-alert-failed').css('display', 'flex');
            } else {
                $('.image-upload-alert-failed').fadeOut()
            }
            if (!nextState.previewImage) {
                $('.image-placeholder').css('display', 'flex')

                $('.image-edit-btn').hide()
            }
            return true
        }
        return false
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 'success') {
            $('.post-upload-alert-success').css('display', 'flex');
        }else if (nextProps.updateStatus === 'success') {
            $('.post-update-alert-success').css('display', 'flex');
        }
        else if (nextProps.imgFailed) {
            $('.img-failed-alert').css('display', 'flex');
            $('.image-edit-btn').css('display', 'inline-block');
            $('.image-placeholder').hide();
        }
        else if (nextProps.status === 'failed') {
            $('.post-upload-alert-failed').css('display', 'flex');
        } else {
            $('.post-upload-alert-success').fadeOut()
            $('.post-upload-alert-failed').fadeOut()
            $('.post-update-alert-success').fadeOut()
            $('.post-update-alert-failed').fadeOut()
            $('.img-failed-alert').fadeOut()
        }
        this.setState({
            modelStatus: nextProps.modelStatus
        })
        const { editData } = nextProps
        if (editData && Object.entries(editData).length > 0) {

            this.setState({
                id : editData.id,
                previewImage: `${Config.apiUrl}/admin/uploads/${editData.image}`,
                items: editData.userInput,
                selectedFields: {
                    productName: editData.productName,
                    companyName: editData.companyName,
                    productCategory: editData.productCategory
                }
            })
        }else{
            this.setState({
                previewImage: '',
                items: [],
                selectedFields: {
                    productName: '',
                    companyName: 1,
                    productCategory: 1
                }
            })
        }
    }

    handleTextChange = (event) => {
        let { userInput } = this.state
        const id = event.target.id;
        const value = event.target.value;
        userInput[id] = value
        this.setState({
            userInput
        })
    }

    handleImageChange = event => {
        const input = $('#selectImage')[0]
        if (input.files) {
            const image = event.target.files[0]
            if (image && isImage(image.name)) {
                let reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({
                        file: image,
                        previewImage: reader.result,
                        notImage: false
                    })
                }
                reader.readAsDataURL(image)

            } else {
                this.setState({
                    notImage: true
                })
            }


        }
    }

    handleChange = (e, t) => {
        let { selectedFields } = this.state
        selectedFields[t] = e.target.value
        this.setState({
            selectedFields
        })
    }

    handleAddModel = () => {
        if (this.state.modelStatus) {

            let newArray = this.state.items;
            let { userInput } = this.state
            let model = userInput.modelNumber
            let details = userInput.productDetails

            if (!model || !details) {
                return
            } else {
                newArray.push({
                    userInput,
                    id: Math.floor(Math.random()*(100-10+1)+10)
                })
                this.setState({
                    items: newArray,
                    userInput: {
                        modelNumber: '',
                        productDetails: ''
                    }
                })
            }
        } else {
            this.setState({
                modelStatus: true
            })
        }
    }

    removeModel = (event) => {
        const idToRemove = Number(event.target.dataset["id"])
        let { items } = this.state
        let result = items.filter(item => item.id !== idToRemove)
        this.setState({
            items: result
        })
    }

    renderAddedModel() {
        const { items } = this.state
        const models = items.map(item => (
            <div className="border rounded-lg p-4 mb-4" key={item.id} >
                <div className="flex w-full items-center justify-between">
                    <label htmlFor="modelNumber" className="text-gray-600 font-semibold">Model</label>
                    <div onClick={this.removeModel} data-id={item.id} className="inline-block text-xs bg-red-200 text-red-500 py-1 px-2 font-semibold rounded-full cursor-pointer hover:bg-red-100 hover:text-red-400">Remove</div>
                </div>
                <input readOnly type="text" value={item.userInput.modelNumber} name="" className="bg-gray-200 border-2 border-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Enter product's model number" required />
                <input readOnly type="text" value={item.userInput.productDetails} name="" className="bg-gray-200 border-2 border-gray-200 py-2 px-3 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Enter a link of product details" required />
            </div>
        ))
        return models
    }

    closeAlert = (type) => {
        $(`.${type}-upload-alert-failed`).fadeOut()
        $(`.${type}-upload-alert-success`).fadeOut()
        $(`.${type}-update-alert-failed`).fadeOut()
        $(`.${type}-update-alert-success`).fadeOut()
        $(`.${type}-img-failed-alert`).fadeOut()
    }


    onSubmit = (e) => {
        e.preventDefault()
        this.handleAddModel()
        const { items, notImage, file, selectedFields } = this.state
        let data = new FormData()

        if (items.length > 0) {
            data.append('userInput', JSON.stringify(items))
        }
        if (!notImage) {
            data.append('image', file)
        }
        data.append('selectedFields', JSON.stringify(selectedFields))

        this.props.onAddNewProduct(data)
        this.setState({
            userInput: {},
            selectedFields: {
                companyName: 1,
                productCategory: 1
            },
            items: [],
            previewImage: ''

        })

    }

    addDefaultSrc(ev) {
        ev.target.src = require('../images/favicon.png')
    }

    keyPress = (e) => {
        if (e.keyCode === 13) {
            e.target.blur()
            $('#modelNumber').focus()
            this.handleAddModel()
        }
    }

    preventEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    onCancelClick = () =>{
        this.props.cancelUpdate()
    }

    onUpdate = () => {
        this.handleAddModel()
        const { items, notImage, file, selectedFields , id} = this.state
        let data = new FormData()
            data.append('id',id)
        if (items.length > 0) {
            data.append('userInput', JSON.stringify(items))
        }
        if (!notImage) {
            data.append('image', file)
        }
        data.append('selectedFields', JSON.stringify(selectedFields))

        this.props.onUpdateProduct(data)
        this.setState({
            userInput: {},
            selectedFields: {
                companyName: 1,
                productCategory: 1
            },
            items: [],
            previewImage: ''

        })
    } 

    render() {
        const { previewImage, modelStatus, userInput, selectedFields } = this.state
        const { companies, categories,editData } = this.props

        return (
            <div className="w-1/2 border-r flex flex-wrap">
                <div className="p-5 w-1/3 bg-white border-r overflow-y-auto h-full">
                    <a href="product.php" className="border-b p-3 w-full flex items-center font-semibold text-teal-500 tracking-wider hover:bg-gray-100"><div className="inline-block bg-teal-200 text-teal-600 rounded-full flex items-center justify-center w-10 h-10 mr-2"><i data-feather="package"></i></div>Products</a>
                </div>
                <div className="p-5 w-2/3 bg-gray-200 h-full overflow-y-auto">
                    <div className="hidden upload-status"></div>
                    <div onClick={() => this.closeAlert('post')} className="alert post-upload-alert-success rounded-lg flex justify-between items-center bg-green-200 text-green-600 text-sm px-4 py-3 mb-4" role="alert">
                        <p>Success! Your post is successfully uploaded.</p>
                        <i data-feather="x" className="feather-sm cursor-pointer close-alert"></i>
                    </div>
                    <div onClick={() => this.closeAlert('post')} className="alert post-update-alert-success rounded-lg flex justify-between items-center bg-green-200 text-green-600 text-sm px-4 py-3 mb-4" role="alert">
                        <p>Success! Your post is successfully updated.</p>
                        <i data-feather="x" className="feather-sm cursor-pointer close-alert"></i>
                    </div>
                    <div onClick={() => this.closeAlert('post')} className="alert img-failed-alert rounded-lg flex justify-between items-center bg-red-200 text-red-600 text-sm px-4 py-3 mb-4" role="alert">
                        <p>Failed! Please Check Your Image.</p>
                        <i data-feather="x" className="feather-sm cursor-pointer close-alert"></i>
                    </div>
                    <div onClick={() => this.closeAlert('post')} className="alert post-upload-alert-failed rounded-lg flex justify-between items-center bg-red-200 text-red-600 text-sm px-4 py-3 mb-4" role="alert">
                        <p>Failed! Your post is not uploaded.</p>
                        <i data-feather="x" className="feather-sm cursor-pointer close-alert"></i>
                    </div>
                    <div className="alert image-upload-alert-failed rounded-lg flex justify-between items-center bg-red-200 text-red-600 text-sm px-4 py-3 mb-4" role="alert">
                        <p>Failed! Your must select an Image!.</p>
                        <div onClick={() => this.closeAlert('image')}>
                            <i data-feather="x" className="feather-sm cursor-pointer" />
                        </div>
                    </div>
                    <div className="bg-white shadow rounded-lg p-5 mb-20">
                        <h1 className="text-xl font-semibold text-gray-600 pb-3 border-b">Add a new product</h1>
                        <form onKeyDown={this.preventEnter} onSubmit={this.onSubmit} method="post" encType="multipart/form-data" className="w-full mt-5" id="myForm">
                            <div className="image-edit-btn select-img inline-block text-xs bg-orange-200 text-orange-500 p-1 px-3 rounded-full font-semibold uppercase mb-2 cursor-pointer hover:bg-orange-100 hover:text-orange-400">Edit Image</div>

                            <div className="w-full bg-gray-200 rounded-lg h-48 cursor-pointer hover:bg-gray-300 flex items-center justify-center select-img image-placeholder">
                                <div className="text-gray-600">
                                    <i data-feather="plus" className="feather-3xl mx-auto"></i>
                                    <p className="text-xl">Select Image</p>
                                </div>
                            </div>

                            {
                                previewImage &&

                                <img src={previewImage} className="w-full h-48 object-cover object-center rounded-lg" alt=""></img>

                            }
                            <div id="previewImage" className="w-full overflow-hidden rounded-lg mb-2" onError={this.addDefaultSrc} />


                            <input type="file" name="image" onChange={this.handleImageChange} id="selectImage" hidden />
                            <label htmlFor="productName" className="text-gray-600 font-semibold">Name</label>
                            <input type="text" onChange={(e) => this.handleChange(e, 'productName')} value={selectedFields.productName || ''} name="productName" id="productName" className="bg-gray-200 border-2 border-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Enter product's name" required />
                            <label htmlFor="companyName" className="text-gray-600 font-semibold">Company</label>
                            <div className="relative">
                                <select onChange={(e) => { this.handleChange(e, 'companyName') }} value={selectedFields.companyName} className="cursor-pointer appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 pr-5 mt-2 mb-4 rounded-lg focus:outline-none focus:bg-white focus:border-teal-500" id="companyName" name="companyName" required>
                                    {
                                        companies.map(company =>
                                            <option value={company.id} key={company.id}>{company.company_name}</option>
                                        )
                                    }

                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 cursor-pointer">
                                    <i data-feather="chevron-down" className="w-5 h-5"></i>
                                </div>
                            </div>
                            <label htmlFor="productCategory" className="text-gray-600 font-semibold">Category</label>
                            <div className="relative">
                                <select onChange={(e) => { this.handleChange(e, 'productCategory') }} value={selectedFields.productCategory} className="cursor-pointer appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 pr-5 mt-2 mb-4 rounded-lg focus:outline-none focus:bg-white focus:border-teal-500" id="productCategory" name="productCategory" required>
                                    {
                                        categories.map(category =>
                                            <option value={category.id} key={category.id}>{category.category_name}</option>
                                        )
                                    }
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 cursor-pointer">
                                    <i data-feather="chevron-down" className="w-5 h-5"></i>
                                </div>
                            </div>
                            {
                                this.renderAddedModel()
                            }
                            {
                                modelStatus &&
                                <div className="border rounded-lg p-4 mb-4">
                                    <div className="flex w-full items-center justify-between">
                                        <label htmlFor={"modelNumber"} className="text-gray-600 font-semibold">Model</label>
                                    </div>
                                    <input type="text" autoFocus ref={this.modelInput} value={userInput.modelNumber || ''} onBlur={this.handleTextChange} onChange={this.handleTextChange} name={"model"} id={"modelNumber"} className="bg-gray-200 border-2 border-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Enter product's model number" />
                                    <input type="text" onKeyDown={this.keyPress} value={userInput.productDetails || ''} onBlur={this.handleTextChange} onChange={this.handleTextChange} name={"productDetails"} id={"productDetails"} className="bg-gray-200 border-2 border-gray-200 py-2 px-3 rounded-lg focus:outline-none focus:border-teal-500 focus:bg-white w-full" placeholder="Enter a link of product details" />
                                </div>
                            }
                            <div onClick={this.handleAddModel} className="btn inline-block rounded-full bg-orange-200 text-orange-500 hover:bg-orange-100 hover:text-orange-400 py-1 px-3 mb-4"><i data-feather="plus" className="inline"></i> Add Model</div>
                            {
                                editData && Object.entries(editData).length > 0 ?
                                    <div className="text-right w-full border-t pt-4">
                                        <div onClick={this.onCancelClick} className="mr-5 inline-block rounded-lg bg-gray-400 py-2 px-3 font-semibold tracking-wide cursor-pointer hover:bg-gray-500">Cancel</div>

                                        <div onClick={this.onUpdate} className="inline-block rounded-lg bg-teal-600 text-white py-2 px-3 font-semibold tracking-wide cursor-pointer hover:bg-teal-700" >Update Product</div>
                                    </div>
                                    :
                                    <div className="text-right w-full border-t pt-4">
                                        <input type="submit" name="" value="Upload Product" className="inline-block rounded-lg bg-teal-600 text-white py-2 px-3 font-semibold tracking-wide cursor-pointer hover:bg-teal-700" />
                                    </div>
                            }
                            {
                                
                            }

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewProduct
