import React,{Component} from 'react'
import errorImage from './img/404.webp'
class ErrorPage extends Component {

    componentDidMount = () => {
        document.title = 'Power Wing | Error'
    }

    componentWillMount(){
        for(let i in styles.body){
            document.body.style[i] = styles.body[i];
        }
    }
    componentWillUnmount(){
        for(let i in styles.body){
            document.body.style[i] = null;
        }
    }

    render() {
        return (
            <div className="container">
                <img src={errorImage} alt="" />
                <h1 style={{fontSize : 32 , fontWeight : 'bold' , marginBottom : 15}}>Page Not Found</h1>
                <p>Sorry, the page you requested could not found.</p>
                <a style={styles.a} href="https://powerwinggroup.com">Go To Home</a>
            </div>
        );
    }
}

export default ErrorPage;

var styles = {
    body : {
        margin: 0,
        padding: 0,
        fontFamily: 'sans-serif',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      },
      a : {
        margin: '20px 0px',
        textDecoration: 'none',
        padding: '10px 20px',
        borderRadius: '100px',
        display: 'inline-block',
        border: '2px solid #dc3545',
        fontWeight: 'bold',
        color: '#dc3545',
        transition: '0.3s ease'
      },
      horver : {
            background:'#dc3545',
            color: '#fff'
      }
}