import React, { Component } from 'react'
import {User,Lock} from 'react-feather'
import $ from 'jquery'

class LoginForm extends Component {

  constructor(props) {
    super(props)
    this.state ={
      data : '1'
    }
  }

  
  shouldComponentUpdate(nextProps,nextState){
    if(this.state.data !== nextState.data){
      if(nextState.data === '0'){
        $('.password-alert').fadeIn();
      }
      return true
    }
    return false
  }

  render() {
    return (

        <form onSubmit={(e) =>this.props.getUserData(e,this)} className="login-form w-1/2 mx-auto relative" data={this.state.data}>
          <div className="bg-red-100 border-l-2 border-red-500 text-red-700 px-4 py-3 mb-10 absolute password-alert w-full" role="alert">
            <p className="font-bold">Login Failed!</p>
            <p className="text-sm">Invalid Username or Password.</p>
          </div>
          <label htmlFor="username" className="font-bold text-gray-700 text-sm"><User className="inline align-text-bottom"/> Username</label>
          <input  type="text" name="username" id="username" className="appearance-none bg-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg w-full border-2 border-gray-200 focus:outline-none focus:bg-white focus:border-teal-500" placeholder="Enter username" required />
          <label htmlFor="password" className="font-bold text-gray-700 text-sm"><Lock className="inline align-text-bottom"/> Password</label>
          <input type="password" name="password" id="password" className="appearance-none bg-gray-200 py-2 px-3 mt-2 mb-4 rounded-lg w-full border-2 border-gray-200 focus:outline-none focus:bg-white focus:border-teal-500" placeholder="Enter password" required />
          <button  className="rounded-lg bg-teal-600 text-white py-2 px-3 font-semibold tracking-wide cursor-pointer hover:bg-teal-700">Sign In </button>
        </form>


    )
  }
}

export default LoginForm
