import axios from 'axios'
import {Config} from '../services/Config'

const instance = axios.create()

instance.interceptors.request.use(async(config) => {
    config.baseURL = Config.apiUrl
    config.headers.type = 'application/json; charset=UTF-8'
    return config
},(error) => {
    console.log(error)
    return Promise.reject(error);
 })

 instance.interceptors.response.use(async(response) => {
    if(!response.data){
        return Promise.reject(response);
     }
    //  console.log("RES",response)
     return response
 },(error) => {
    // report_apierror(response)
    return Promise.reject(error);
 })

 export default instance